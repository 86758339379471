import React, { useContext } from "react";
import { Container, Row, Col, Button, Form, Input } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTwitter,
  faFacebookF,
  faLinkedinIn,
} from "@fortawesome/free-brands-svg-icons";
import { faEnvelope as faEnvelopeSolid, faPhone } from "@fortawesome/free-solid-svg-icons";
import "../../assetss/scss/Footer.scss";
// import InstitutionalMembers from "../home/InstitutionalMembers";
import { Link } from "react-router-dom";
import { CommonContext } from "../../context";

const Footer = () => {
  const { indexData } = useContext(CommonContext);

  const Logo = indexData && indexData?.settings?.length > 0 && indexData?.settings.find(item => item.option_key === 'logo')?.option_value;
  const fbLink = indexData && indexData?.settings?.length > 0 && indexData?.settings.find(item => item.option_key === 'fb_link')?.option_value;
  const xLink = indexData && indexData?.settings?.length > 0 && indexData?.settings.find(item => item.option_key === 'x_link')?.option_value;
  const linkedinLink = indexData && indexData?.settings?.length > 0 && indexData?.settings.find(item => item.option_key === 'linkedin_link')?.option_value;
  const emailContact = indexData && indexData?.settings?.length > 0 && indexData?.settings.find(item => item.option_key === 'contact_email')?.option_value;
  const cAddress = indexData && indexData?.settings?.length > 0 && indexData?.settings.find(item => item.option_key === 'contact_address')?.option_value;
  const cPhone = indexData && indexData?.settings?.length > 0 && indexData?.settings.find(item => item.option_key === 'contact_phone')?.option_value;
  return (
    <>
      {/* <InstitutionalMembers /> */}
      <footer className="footer-container">
        <Container className="footer-overlay">
          <Row className="justify-content-center text-center mb-4">
            <Col md="2">
              <Button className="footer-button">BE A MEMBER</Button>
            </Col>
            <Col md="2">
              <Button className="footer-button">CONTACT</Button>
            </Col>
            <Col md="2">
              <Button className="footer-button">QUICK LINKS</Button>
            </Col>
            <Col md="2">
              <Button className="footer-button">INFORMATION</Button>
            </Col>
            <Col md="2">
              <Button className="footer-button">SUBSCRIBE US</Button>
            </Col>
          </Row>
          <Row className="text-white mb-4">
            <Col md="4" className="mb-4">
              <h5>Contact Us</h5>
              <p>
                {cAddress}
              </p>
              <p>
                <Link to="#" className="contact-link">
                  Contact Us
                </Link>
              </p>
              <p className="m-0">
              <FontAwesomeIcon icon={faPhone} /> {cPhone}
              </p>
              <p>
                <FontAwesomeIcon icon={faEnvelopeSolid} /> {emailContact}
              </p>
              <img src={indexData?.settings_path + '/' + Logo} alt="IOV Logo" className="footer-logo" />
            </Col>
            <Col md="3" className="mb-4 col-6">
              <h5>Main Menu</h5>
              <ul className="list-unstyled">
                <li>
                  <Link to="#"> About Us</Link>
                </li>
                <li>
                  <Link to="#"> Hon. Gen. Secretary Desk</Link>
                </li>
                <li>
                  <Link to="#"> Council 2022-2024</Link>
                </li>
                <li>
                  <Link to="#"> Bye-Laws of IOV</Link>
                </li>
                <li>
                  <Link to="#"> Join IOV</Link>
                </li>
                <li>
                  <Link to="#"> IOV Branches</Link>
                </li>
              </ul>
            </Col>
            <Col md="3" className="mb-4 col-6">
              <h5>Support</h5>
              <ul className="list-unstyled">
                <li>
                  <Link to="#"> Grievance</Link>
                </li>
                <li>
                  <Link to="#"> List of Member</Link>
                </li>
                <li>
                  <Link to="/news"> News</Link>
                </li>
                <li>
                  <Link to="#"> Events</Link>
                </li>
                <li>
                  <Link to="#"> Gallery</Link>
                </li>
                <li>
                  <Link to="#"> Privacy Policy</Link>
                </li>
              </ul>
            </Col>
            <Col md="2" className="mb-4 col-6">
              <h5>Company</h5>
              <ul className="list-unstyled">
                <li>
                  <Link to="#"> 24/7 Support</Link>
                </li>
                <li>
                  <Link to="#"> Locate Us</Link>
                </li>
                <li>
                  <Link to="#"> For Investors</Link>
                </li>
                <li>
                  <Link to="#"> Gallery</Link>
                </li>
                <li>
                  <Link to="#"> Event Details</Link>
                </li>
              </ul>
            </Col>
          </Row>
          <hr className="footer-divider" />
          <Row className="d-flextext-white mt-4 footer-bottom col-12 ">
            <Col md="6" className=" justify-content-center order-mobile-2">
              <p>
                Terms of Use &nbsp; | &nbsp; Privacy Policy &nbsp; | &nbsp; Data
                Security &nbsp; | &nbsp; Cookie Setting
              </p>
              <br />
              <h6>©2024 All Rights Reserved. Terms & Privacy Policies</h6>
            </Col>

            <Col md="6" className=" newsletter-container order-mobile-1">
              <Row className="d-flex col-12">
                <Col md="6" className="newsletter-title-footer">
                  <h4>Newsletter</h4>
                </Col>
                <Col md="6" className="text-right">
                  <ul class="social-icons d-flex ml-3">
                    <li class="icon-container">
                      <Link
                        to={fbLink}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FontAwesomeIcon icon={faFacebookF} size="1x" />
                      </Link>
                    </li>
                    <li class="icon-container">
                      <Link
                        to={xLink}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FontAwesomeIcon icon={faTwitter} size="1x" />
                      </Link>
                    </li>
                    <li class="icon-container">
                      <Link
                        to={linkedinLink}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FontAwesomeIcon icon={faLinkedinIn} size="1x" />
                      </Link>
                    </li>
                    <li class="icon-container">
                      <Link to={emailContact}>
                        <FontAwesomeIcon icon={faEnvelopeSolid} size="1x" />
                      </Link>
                    </li>
                  </ul>
                </Col>
              </Row>
              <Form className="w-100">
                <Input
                  type="email"
                  placeholder="Enter Your Email Id"
                  className="newsletter-input mr-2"
                />
                <Button className="subscribe-button">Subscribe</Button>
              </Form>
            </Col>
          </Row>
        </Container>
      </footer>
    </>
  );
};

export default Footer;
