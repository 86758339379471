import React, { createContext, useState } from 'react';

export const EventsContext = createContext();

export function EventsContextProvider({ children }) {
    

    const [addModal, setAddModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isSearch, setIsSearch] = useState(false);
    const [globalSearch, setGlobalSearch] = useState('');

    const handleAddModals = () => {
        setAddModal(true);
    };

    const handleCloseModal = () => {
        setAddModal(false);
    };

    const contextValue = {
        isSearch,
        globalSearch,
        isLoading,
        handleAddModals,
        handleCloseModal,
        setAddModal,
        addModal,
        setIsLoading,
        setGlobalSearch,
        setIsSearch
    };

    return <EventsContext.Provider value={contextValue}>{children}</EventsContext.Provider>;
}
