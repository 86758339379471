import React, { createContext, useEffect, useState } from 'react';
import commonApi from '../utiles/api';

export const HomeContext = createContext();

export function HomeContextProvider({ children }) {
    

    const [modal, setModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isSearch, setIsSearch] = useState(false);
    const [globalSearch, setGlobalSearch] = useState('');
    const [instMem, setInstMem] = useState([]);
    const [articleData, setArticleData] = useState([]);
    const [testimonials, setTestimonials] = useState([]);
    const [instPath, setInstPath] = useState('');

    const handleAddModals = () => {
        setModal(true);
    };

    const handleCloseModal = () => {
        setModal(false);
    };
    const onBranchLoadApi = async () => {
        setIsLoading(true);
        try {
            const response = await commonApi.post('members/insti-mem/front');
            if (response.data.s === 1) {
                setInstMem(response.data.data);
                setInstPath(response.data.path);
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                console.error('Unauthorized access - Redirecting to login');
            } else {
                console.error('Failed to fetch Data:', error);
            }
        } finally {
            setIsLoading(false);
        }
    };
    const onArticleLoadApi = async () => {
        setIsLoading(true);
        try {
            const response = await commonApi.post('articles/front/list');
            if (response.data.s === 1) {
                setArticleData(response.data);
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                console.error('Unauthorized access - Redirecting to login');
            } else {
                console.error('Failed to fetch Data:', error);
            }
        } finally {
            setIsLoading(false);
        }
    };
    const ontestimonialsApi = async () => {
        setIsLoading(true);
        try {
            const response = await commonApi.post('testimonials/front');
            if (response.data.s === 1) {
                setTestimonials(response?.data?.data);
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                console.error('Unauthorized access - Redirecting to login');
            } else {
                console.error('Failed to fetch Data:', error);
            }
        } finally {
            setIsLoading(false);
        }
    };
   
    useEffect(() => {
        onBranchLoadApi();
        onArticleLoadApi();
        ontestimonialsApi();
    }, []);
    const contextValue = {
        testimonials,
        onArticleLoadApi,
        onBranchLoadApi,
        articleData,
        instPath,
        instMem,
        isSearch,
        globalSearch,
        isLoading,
        handleAddModals,
        handleCloseModal,
        setModal,
        modal,
        setIsLoading,
        setGlobalSearch,
        setIsSearch
    };

    return <HomeContext.Provider value={contextValue}>{children}</HomeContext.Provider>;
}
