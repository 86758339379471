import React, { useContext, useEffect, useState } from "react";
import {
    Navbar,
    NavbarBrand,
    Nav,
    NavItem,
    NavLink,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from "reactstrap";
import { FaBars, FaTimes } from "react-icons/fa";
import "../../assetss/scss/Header.scss";
// import Logo from "../../assetss/img/logo.png";
import { Link } from "react-router-dom";
import { CommonContext } from "../../context";
import { Helmet } from 'react-helmet-async';

const Header = () => {
    const { indexData } = useContext(CommonContext);
    const Logo = indexData && indexData?.settings?.length > 0 && indexData?.settings.find(item => item.option_key === 'logo')?.option_value;
    const favicon = indexData && indexData?.settings?.length > 0 && indexData?.settings.find(item => item.option_key === 'favicon')?.option_value;
    const siteTitle = indexData && indexData?.settings?.length > 0 && indexData?.settings.find(item => item.option_key === 'site_title')?.option_value;
    const siteKeywords = indexData && indexData?.settings?.length > 0 && indexData?.settings.find(item => item.option_key === 'site_keywords')?.option_value;
    const siteDescription = indexData && indexData?.settings?.length > 0 && indexData?.settings.find(item => item.option_key === 'site_desc')?.option_value;

    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isSubMenuOpen, setIsSubMenuOpen] = useState({});

    useEffect(() => {
        if (favicon) {
            const faviconLink = document.querySelector("link[rel='icon']");
            if (faviconLink) {
                faviconLink.href = `${indexData?.path}/${favicon}`;
            }
            console.log('faviconLink', faviconLink)
        }
    }, [favicon]);
    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const toggleSubMenu = (menu) => {
        setIsSubMenuOpen({
            ...isSubMenuOpen,
            [menu]: !isSubMenuOpen[menu],
        });
    };

    return (
        <>
            <Helmet>
                <title>{siteTitle}</title>
                <meta name="description" content={siteDescription} />
                <meta name="keywords" content={siteKeywords} />
            </Helmet>
            <div
                className={`overlay ${isMenuOpen ? "active" : ""}`}
                onClick={toggleMenu}
            ></div>
            <div className="top-strip">
                <div className="top-strip-marquee">
                    <marquee>
                        {indexData?.alerts?.title}
                    </marquee>
                </div>
                <Nav >
                    <NavItem>
                        <Link className="nav-link" to="/">Ekta</Link>
                    </NavItem>
                    <NavItem>
                        <Link className="nav-link" to="/">Tender</Link>
                    </NavItem>
                    <NavItem>
                        <Link className="nav-link" to="/signin">Login</Link>
                    </NavItem>
                    <NavItem>
                        <Link className="nav-link" to="/signup">Register</Link>
                    </NavItem>
                </Nav>
            </div>
            <Navbar color="light" light expand="md">
                <NavbarBrand href="/">
                    <img src={indexData?.settings_path + '/' + Logo} alt="IOV Logo" className="logo" />
                </NavbarBrand>
                <div className="ml-auto d-md-none">
                    <FaBars onClick={toggleMenu} className="menu-icon" />
                </div>
                <Nav className="ml-auto d-none d-md-flex" navbar>
                    <NavItem>
                        <Link className="nav-link" to="/">Home</Link>
                    </NavItem>
                    <UncontrolledDropdown nav inNavbar className="dropdown-hover">
                        <DropdownToggle nav caret>
                            About
                        </DropdownToggle>
                        <DropdownMenu right>
                            <DropdownItem><Link to={'/about'} className="dropdown-item">Who We Are</Link></DropdownItem>
                            <DropdownItem><Link to={'/about-founder'} className="dropdown-item">About Founder</Link></DropdownItem>
                            <DropdownItem><Link to={'/about-secretary'} className="dropdown-item">Hon. Gen. Secretary Desk</Link></DropdownItem>
                            <DropdownItem><Link to={'/council'} className="dropdown-item">Council 2022-2024</Link></DropdownItem>
                            <DropdownItem><Link to={'/blue-book'} className="dropdown-item">Blue Book</Link></DropdownItem>
                        </DropdownMenu>
                    </UncontrolledDropdown>
                    <UncontrolledDropdown nav inNavbar className="dropdown-hover">
                        <DropdownToggle nav caret>
                            Join IOV
                        </DropdownToggle>
                        <DropdownMenu right>
                            <DropdownItem>
                                <Link to={'/join-iov/individual-membership'} className="dropdown-item">
                                    Individual Membership
                                </Link>
                            </DropdownItem>
                        </DropdownMenu>
                    </UncontrolledDropdown>
                    <NavItem>
                        <Link className="nav-link" to="/list-of-member">List Of Member</Link>
                    </NavItem>
                    <NavItem>
                        <Link className="nav-link" to="/grievance">Grievance</Link>
                    </NavItem>
                    <NavItem>
                        <Link className="nav-link" to="/publications">Publications</Link>
                    </NavItem>
                    <NavItem>
                        <Link className="nav-link" to="/event">Event</Link>
                    </NavItem>
                    <NavItem>
                        <Link className="nav-link" to="/branches">Branches</Link>
                    </NavItem>
                    <NavItem>
                        <Link className="nav-link" to="/contact">Contact</Link>
                    </NavItem>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 50 50"
                        id="hamburger-menu"
                    >
                        <path
                            fill="#231F20"
                            d="M8.667 15h30a1 1 0 100-2h-30a1 1 0 100 2zM8.667 37h30a1 1 0 100-2h-30a1 1 0 100 2zM8.667 26h30a1 1 0 100-2h-30a1 1 0 100 2z"
                        ></path>
                    </svg>
                </Nav>
            </Navbar>
            <div className={`mobile-menu ${isMenuOpen ? "open" : ""}`}>
                <div className="mobile-menu-header">
                    <img src={Logo} alt="IOV Logo" className="logo" />
                    <FaTimes onClick={toggleMenu} className="close-icon" />
                </div>
                <Nav navbar>
                    <NavItem>
                        <Link className="nav-link" to="/" onClick={toggleMenu}>
                            Home
                        </Link>
                    </NavItem>
                    <UncontrolledDropdown nav inNavbar>
                        <DropdownToggle
                            nav
                            caret
                            onClick={(e) => {
                                e.preventDefault();
                                toggleSubMenu("about");
                            }}
                        >
                            About
                        </DropdownToggle>
                        <DropdownMenu
                            right
                            className={isSubMenuOpen["about"] ? "show" : ""}
                        >
                            <DropdownItem href="/about" onClick={toggleMenu}>
                                Who We Are
                            </DropdownItem>
                            <DropdownItem href="/about-founder" onClick={toggleMenu}>
                                About Founder
                            </DropdownItem>
                            <DropdownItem href="/about-secretary" onClick={toggleMenu}>
                                Hon. Gen. Secretary Desk
                            </DropdownItem>
                            <DropdownItem href="/council" onClick={toggleMenu}>
                                Council 2022-2024
                            </DropdownItem>
                            <DropdownItem href="/blue-book" onClick={toggleMenu}>
                                Blue Book
                            </DropdownItem>
                        </DropdownMenu>
                    </UncontrolledDropdown>
                    <UncontrolledDropdown nav inNavbar>
                        <DropdownToggle
                            nav
                            caret
                            onClick={(e) => {
                                e.preventDefault();
                                toggleSubMenu("join");
                            }}
                        >
                            Join IOV
                        </DropdownToggle>
                        <DropdownMenu right className={isSubMenuOpen["join"] ? "show" : ""}>
                            <DropdownItem
                                href="/join-iov/individual-membership"
                                onClick={toggleMenu}
                            >
                                Individual Membership
                            </DropdownItem>
                        </DropdownMenu>
                    </UncontrolledDropdown>
                    <NavItem>
                        <Link className="nav-link" to="/list-of-member" onClick={toggleMenu}>
                            List Of Member
                        </Link>
                    </NavItem>
                    <NavItem>
                        <Link className="nav-link" to="/grievance" onClick={toggleMenu}>
                            Grievance
                        </Link>
                    </NavItem>
                    <NavItem>
                        <Link className="nav-link" to="/publications" onClick={toggleMenu}>
                            Publications
                        </Link>
                    </NavItem>
                    <NavItem>
                        <Link className="nav-link" to="/event" onClick={toggleMenu}>
                            Event
                        </Link>
                    </NavItem>
                    <NavItem>
                        <Link className="nav-link" to="/branches" onClick={toggleMenu}>
                            Branches
                        </Link>
                    </NavItem>
                    <NavItem>
                        <Link className="nav-link" to="/contact" onClick={toggleMenu}>
                            Contact
                        </Link>
                    </NavItem>
                </Nav>
            </div>
        </>
    );
};

export default Header;
