import React, { Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { HelmetProvider } from 'react-helmet-async';
import Header from './component/common/Header';
import { CommonContextProvider } from './context';
import Home from './component/home';
import Footer from './component/common/Footer';
import Contact from './component/contact';
import About from './component/about';
import Grievance from './component/grievance';
import News from './component/news';
import Events from './component/events';
import Newsdetails from './component/newsdetails';
import Eventdetails from './component/eventdetails';
import Journal from './component/journal';
import InstitutionalMembers from './component/institutionalMembers';
import Branches from './component/branches';
import Publications from './component/publications';
import JournalDetails from './component/journalDetails';
import Blogs from './component/blogs';
import BlogDetails from './component/blogsDetails';
import Article from './component/article';
import PreLoader from './component/common/PreLoader';
import Special from './component/special';
import SpecialDetails from './component/specialDetails';
import Articledetails from './component/articledetails';

const SignIn = React.lazy(() => import('./component/common/SignIn'));
const SignUp = React.lazy(() => import('./component/common/SignUp'));
const ForgotPassword = React.lazy(() => import('./component/common/ForgotPassword'));
const AboutFounder = React.lazy(() => import('./component/about/AboutFounder'));
const AboutSecretary = React.lazy(() => import('./component/about/AboutSecretary'));
const Council = React.lazy(() => import('./component/about/Council'));
const BlueBook = React.lazy(() => import('./component/about/BlueBook'));

const App = () => {
  return (
    <CommonContextProvider>
      <Suspense fallback={<PreLoader />}>
        <HelmetProvider>
          <>
            <Header />
            <Routes>
              <Route path="/signin" exact element={<SignIn />} />
              <Route path="/signup" exact element={<SignUp />} />
              <Route path="/forgot-password" exact element={<ForgotPassword />} />
              <Route path="/" exact element={<Home />} />
              <Route path="/about" element={<About />} />
              <Route path="/about-founder" element={<AboutFounder />} />
              <Route path="/about-secretary" element={<AboutSecretary />} />
              <Route path="/council" element={<Council />} />
              <Route path="/blue-book" element={<BlueBook />} />
              <Route path="/join-iov" component={() => <div>Join IOV</div>} />
              <Route path="/institutional-members" element={<InstitutionalMembers />} />
              <Route path="/grievance" element={<Grievance />} />
              <Route path="/e-journal" element={<Journal />} />
              <Route path="/e-journal/details" element={<JournalDetails />} />
              <Route path="/publications" element={<Publications />} />
              <Route path="/event" element={<Events />} />
              <Route path="/event-details" element={<Eventdetails />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/news" element={<News />} />
              <Route path="/news-details" element={<Newsdetails />} />
              <Route path="/branches" element={<Branches />} />
              <Route path="/blogs" element={<Blogs />} />
              <Route path="/blogs-details" element={<BlogDetails />} />
              <Route path="/article" element={<Article />} />
              <Route path="/article-details" element={<Articledetails />} />
              <Route path="/special-certificate-program" element={<Special />} />
              <Route path="/program-details" element={<SpecialDetails />} />
            </Routes>
            <Footer />
          </>
        </HelmetProvider>
      </Suspense>
    </CommonContextProvider>
  );
};

export default App;
