import React from "react";
import loaderOuter from '../../assetss/img/loader_outer.png'
import loaderInner from '../../assetss/img/loader_inner.png'
import '../../assetss/scss/preLoader.scss'

const PreLoader = () => {
    return (
        <div className="pre-loader loader1">
            <div className="loader-inner">
                <img src={loaderOuter} alt="Rotating Image" className="rotate-image" />
                <img src={loaderInner} alt="Rotating Image" className="logo-overlay" style={{ width: '74px' }} />
            </div>
        </div>
    );
};

export default PreLoader