import React, { createContext, useEffect, useState } from 'react';
import commonApi from '../utiles/api';

export const BranchesContext = createContext();

export function BranchesContextProvider({ children }) {


    const [addModal, setAddModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isSearch, setIsSearch] = useState(false);
    const [globalSearch, setGlobalSearch] = useState('');
    const [branchListData, setBranchListData] = useState([]);
    const [uBranchState, setUBranchState] = useState([]);

    const handleAddModals = () => {
        setAddModal(true);
    };

    const handleCloseModal = () => {
        setAddModal(false);
    };
    const onBranchLoadApi = async () => {
        setIsLoading(true);
        try {
            const response = await commonApi.post('branch/front/list');
            if (response.data.s === 1) {
                // setBranchListData(response.data.data);
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                console.error('Unauthorized access - Redirecting to login');
            } else {
                console.error('Failed to fetch desListData:', error);
            }
        } finally {
            setIsLoading(false);
        }
    };
    const onUniqueBranchState = async () => {
        setIsLoading(true);
        try {
            const response = await commonApi.post('/branch/branch-unique-states');
            if (response.data.s === 1) {
                setUBranchState(response.data.data);
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                console.error('Unauthorized access - Redirecting to login');
            } else {
                console.error('Failed to fetch desListData:', error);
            }
        } finally {
            setIsLoading(false);
        }
    };
    useEffect(() => {
        onBranchLoadApi();
        onUniqueBranchState();
    }, []);
    const contextValue = {
        onUniqueBranchState,
        uBranchState,
        setUBranchState,
        branchListData,
        setBranchListData,
        isSearch,
        globalSearch,
        isLoading,
        handleAddModals,
        handleCloseModal,
        setAddModal,
        addModal,
        setIsLoading,
        setGlobalSearch,
        setIsSearch
    };

    return <BranchesContext.Provider value={contextValue}>{children}</BranchesContext.Provider>;
}
